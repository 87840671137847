import { Sitka } from 'olio-sitka'
import { Store } from 'redux'
import { CurrentSessionState } from 'common/redux/sitka/current_session/core'
import { OctainModelState } from 'common/redux/sitka/octain_model/core'
import { OctainPredictionState } from 'common/redux/sitka/octain_prediction/core'
import { OctainDatasetState } from 'common/redux/sitka/octain_dataset/core'
import { AdminState } from 'common/redux/sitka/admin/core'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AdminModule } from 'common/redux/sitka/admin/module'

const LOGGING: boolean = window._env_.REACT_APP_LOGGING === 'true' || false

export interface AppModules {
  readonly currentSession: CurrentSessionModule
  readonly models: ModelModule
  readonly predictions: PredictionModule
  readonly datasets: DatasetModule
  readonly admin: AdminModule
}

export interface AppState {
  readonly currentSession: CurrentSessionState
  readonly models: OctainModelState
  readonly predictions: OctainPredictionState
  readonly datasets: OctainDatasetState
  readonly admin: AdminState
}

export const sitka = new Sitka<AppModules>({
  log: LOGGING,
  sitkaInState: false
})
sitka.register([
  new ModelModule(),
  new PredictionModule(),
  new DatasetModule(),
  new CurrentSessionModule(),
  new AdminModule()
])

export const store: Store = sitka.createStore() as Store
