import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { App } from './app'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/table/lib/css/table.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import './index.css'
import { store } from './common/redux/sitka/sitka_octain'
import ReactGA from 'react-ga4'
import { Auth0Provider } from "@auth0/auth0-react";

require('dotenv').config()

interface EnvironmentDockerReact {
  REACT_APP_USE_MOCK: string;
  REACT_APP_API_HOST: string;
  REACT_APP_LOGGING: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_HOST: string;
  REACT_APP_GA: string;
}

declare global {
  interface Window {
    _env_: EnvironmentDockerReact;
  }
}

const GOOGLE_ANALYTICS: string = window._env_.REACT_APP_GA || ''
const AUTH0_DOMAIN: string = window._env_.REACT_APP_AUTH0_DOMAIN || ''
const AUTH0_CLIENT_ID: string = window._env_.REACT_APP_CLIENT_ID || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'

// Hook Google Analytics Initialization
if (GOOGLE_ANALYTICS !== '') {
  ReactGA.initialize(GOOGLE_ANALYTICS);
  if (LOGGING) console.log(`GA Initialized`, GOOGLE_ANALYTICS);
}

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)
