import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { SmallToggleButton } from 'ui/styledComponents/buttons'
import {
  Container,
  H2,
  PageHeadingContainer,
  H1,
  RefreshButton
} from 'ui/styledComponents/constants'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import { FetchStatus } from 'enums'
import admin_styles from 'ui/components/admin/admin_styles'
import { AdminModule } from 'common/redux/sitka/admin/module'
import { UserList } from 'ui/components/admin/admin_user_list'
import ReactGA from 'react-ga4'

const GOOGLE_ANALYTICS: string = window._env_.REACT_APP_GA || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'

interface PredictionsProps {
  readonly adminModule: AdminModule
  readonly sitkaState: AppState
}

const Component: React.FC<PredictionsProps> = ({
  adminModule,
  sitkaState: {
    admin: { users, status }
  }
}) => {
  
  useEffect(() => {   
    adminModule.handleGetUsers()

    // Hook Google Analytics Initialization
    if (GOOGLE_ANALYTICS !== '') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname});
      if (LOGGING) console.log(`GA Page View`, window.location.pathname);
    }
  }, [])

  const {
    EmptyAdminList,
    AdminHeadingIcon,
    PageButtonContainer,
    LoadingSpinner
  } = admin_styles

  const loading = (
    <>
      <EmptyAdminList>
        <LoadingSpinner />
      </EmptyAdminList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const none = loading

  const error = (
    <>
      <EmptyAdminList>
        <H1>Network Error</H1>
      </EmptyAdminList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const done =
    status === FetchStatus.done && users.sort.length === 0 ? (
      <>
        <EmptyAdminList>
          <h2>{'No Other Users'}</h2>
        </EmptyAdminList>
        <PageButtonContainer>
          <PageButtons />
        </PageButtonContainer>
      </>
    ) : (
      <UserList users={users} />
    )

  const getUsers = () => {
    adminModule.handleGetUsers()
  }

  return (
    <Container>
      <PageHeadingContainer>
        <H2>
          <AdminHeadingIcon />
          {'Users'}
        </H2>
        <PageButtonContainer>
          <SmallToggleButton
            onClick={getUsers}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
        </PageButtonContainer>
      </PageHeadingContainer>
      <LoadingWrapper {...{ done, loading, error, none, status }} />
    </Container>
  )
}

export const Admin = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    adminModule: modules.admin,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
