import axios, { AxiosResponse, AxiosError } from 'axios'
import { mockDataset, mockModel, mockSessionData } from 'common/mockData'
import {
  ModelWizard,
  PredictionWizard
} from 'common/redux/sitka/current_session/core'
import {
  CreateModelRemote,
  CreatePredictionRemote
} from 'common/redux/sitka/current_session/remote'
import { eventChannel, buffers } from 'redux-saga' //, END
import {
  getDatasetsParams,
  patchDatasetsParams,
  patchPredictionsParams,
  getPredictionsParams,
  getModelsParams,
  patchUserParams
} from 'common/request_shape'
import { NewUserWizard } from 'common/redux/sitka/admin/core'
import ReactGA from 'react-ga4'

const USE_MOCK = (window._env_.REACT_APP_USE_MOCK || 'false') === 'true'
const REACT_APP_API_HOST = window._env_.REACT_APP_API_HOST || ''
const REACT_APP_CLIENT_ID = window._env_.REACT_APP_CLIENT_ID || ''
const REACT_APP_HOST = window._env_.REACT_APP_HOST || ''
const REACT_APP_AUTH0_DOMAIN = window._env_.REACT_APP_AUTH0_DOMAIN || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'

axios.defaults.baseURL = REACT_APP_API_HOST

export interface ResponseModel {
  data?: any
  status: number
  statusText: string
}

const handleLogout = (unkownUser: boolean = false) => {

  //console.log(`Clearing cookies and logging out!`)

  // Clear all of our cookies for the local domain
  document.cookie.split(";").forEach((c) => {
    console.log(`Cookie`, c)
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });    

  const returnTo = encodeURI(REACT_APP_HOST + (unkownUser ? '/login/unknown' : ''))
  window.location.replace(`https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${returnTo}&client_id=${REACT_APP_CLIENT_ID}`)

}

const handleResp = (resp: AxiosResponse): ResponseModel => {
  if (LOGGING) console.log(`Handle Response`, resp)

  if (resp.status == 401) {
    handleLogout()
  }

  // handle unknown user
  if (resp.status == 418) {
    handleLogout(true)
  }

  if (resp.status !== 200) {
    return { status: resp.status, statusText: resp.statusText }
  }

  resp.headers['x-jwt-token'] &&
    localStorage.setItem('octainSessionToken', resp.headers['x-jwt-token'])

  return {
    data: resp.data,
    status: resp.status,
    statusText: resp.statusText
  }
}

const handleErrorResp = (resp: AxiosError): ResponseModel => {
  if (LOGGING) console.log(`handleErrorResp`, resp.response)

  if (resp.response?.status == 401) {
    handleLogout()
  }

  if (resp.response?.status == 418) {
    handleLogout(true)
  }

  if (!resp.response) {
    return { status: 404, statusText: 'Network Error' }
  }

  return {
    status: resp.response.status,
    statusText: resp.response.statusText
  }
}

enum ContentType {
  'application/json;charset=UTF-8',
  'multipart/form-data'
}

const buildHeader = (
  type: ContentType = ContentType['application/json;charset=UTF-8']
) => {
  return {
    Authorization: `Bearer ${localStorage.getItem('octainSessionToken')}`,
    'Content-Type': ContentType[type]
  }
}

// Datasets
export const getDatasets = (
  params: getDatasetsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockDataset
  }

  ReactGA.event({
    category: 'User',
    action: 'Get Dataset'
  });

  return axios
    .get<ResponseModel>(`/datasets`, {
      params: params,
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const createDatasetUploadChannel = (data: FormData) => {
  return eventChannel(emitter => {
    const onProgress = (event: any) => {
      emitter({ event })
    }

    const onError = (error: any) => {
      emitter({ error: handleErrorResp(error) })

      ReactGA.event({
        category: 'User',
        action: 'Create Dataset Error'
      });

      return handleErrorResp(error)
    }

    const onSuccess = (resp: AxiosResponse<ResponseModel>) => {
      emitter({ success: handleResp(resp) })

      ReactGA.event({
        category: 'User',
        action: 'Create Dataset Success'
      });

      return handleResp(resp)
    }

    axios
      .put<ResponseModel>(`/datasets`, data, {
        onUploadProgress: onProgress,
        headers: buildHeader(ContentType['multipart/form-data'])
      })
      .then(onSuccess)
      .catch(onError)

    return () => {}
  }, buffers.sliding(2))
}

export const patchDataset = (
  id: string,
  params: patchDatasetsParams
): Promise<ResponseModel> => {

  ReactGA.event({
    category: 'User',
    action: 'Patch Dataset'
  });

  return axios
    .patch(`/datasets/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Models
export const createModel = (
  modelSettings: ModelWizard
): Promise<ResponseModel> => {

  //console.log(`Generate Model Submit: create Model`)

  const new_model: CreateModelRemote = {
    dataset_id: parseInt(modelSettings.datasetId),
    id_variable: modelSettings.idVariable,
    method: modelSettings.method,
    name: modelSettings.modelName,
    prediction_variables: modelSettings.predictors,
    response_variable: modelSettings.responseVariable,
    training_time: parseInt(modelSettings.trainingTime),
    dataset_type: modelSettings.datasetType,
    
  }
  
  ReactGA.event({
    category: 'User',
    action: 'Create Model'
  });

  return axios
    .post<ResponseModel>(`/models`, new_model, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getOctainModels = (
  params: getModelsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockModel
  }

  ReactGA.event({
    category: 'User',
    action: 'Get Models'
  });

  return axios
    .get('/models', {
      params: params,
      headers: buildHeader(ContentType['application/json;charset=UTF-8'])
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getOctainModel = (modelId: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockModel
  }

  ReactGA.event({
    category: 'User',
    action: 'Get Model'
  });

  return axios
    .get(`/models/${modelId}`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchModel = (
  id: string,
  new_values: patchPredictionsParams
): Promise<ResponseModel> => {

  ReactGA.event({
    category: 'User',
    action: 'Patch Model'
  });

  return axios
    .patch(`/models/${id}`, new_values, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Auth
export const login = (idToken: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockSessionData
  }

  ReactGA.event({
    category: 'User',
    action: 'Login'
  });

  return axios
    .get(`/login`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: idToken
      }
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const checkAuth = (): Promise<ResponseModel> => {
  return axios
    .get<ResponseModel>(`/auth`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const get_file = (url: string): Promise<ResponseModel> => {
  return axios
    .get(url)
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Predictions
export const createPrediction = (
  predictionSettings: PredictionWizard
): Promise<ResponseModel> => {
  const new_prediction: CreatePredictionRemote = {
    name: predictionSettings.predictionName,
    dataset_id: parseInt(predictionSettings.datasetId),
    model_id: parseInt(predictionSettings.modelId)
  }

  ReactGA.event({
    category: 'User',
    action: 'Create Prediction'
  });

  return axios
    .post<ResponseModel>(`/predictions`, new_prediction, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getPredictions = (
  params: getPredictionsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    // TODO: return mock prediction
  }

  ReactGA.event({
    category: 'User',
    action: 'Get Predictions'
  });

  return axios
    .get('/predictions', {
      params: params,
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getPrediction = (predictionId: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    // TODO: return mock prediction
  }

  ReactGA.event({
    category: 'User',
    action: 'Get Prediction'
  });

  return axios
    .get(`/predictions/${predictionId}`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchPrediction = (
  id: string,
  params: patchPredictionsParams
): Promise<ResponseModel> => {

  ReactGA.event({
    category: 'User',
    action: 'Patch Prediction'
  });

  return axios
    .patch(`/predictions/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Admin

export const createUser = (user: NewUserWizard): Promise<ResponseModel> => {
  
  ReactGA.event({
    category: 'Admin',
    action: 'Create User'
  });

  return axios
    .post(`/users`, user, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getUsers = (): Promise<ResponseModel> => {

  ReactGA.event({
    category: 'User',
    action: 'Get Users'
  });

  return axios
    .get(`/users`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchUser = (
  id: string,
  params: patchUserParams
): Promise<ResponseModel> => {

  ReactGA.event({
    category: 'User',
    action: 'Patch User'
  });

  return axios
    .patch(`/users/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}
