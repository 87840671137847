import styled from 'styled-components'
import { 
  flex, 
  // cn, 
  margin } from 'ui/styledComponents/constants'
import { Spinner, ProgressBar } from '@blueprintjs/core'

export default {
  EmptyDatalistContainer: styled.div`
    height: 420px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    ${flex.flexCenteredColumn}
    ${margin.m1}
    justify-content: center;
  `,
  LoadingSpinner: styled(Spinner).attrs(() => ({
    className: 'LoadingSpinner',
    size: 20
  }))``,
  PageButtonContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  DatalistContainer: styled.div`
    height: 420px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    ${flex.flexCenteredColumn}
    ${margin.m1}
    overflow-x: auto;
  `,
  UploadProgress: styled(ProgressBar)`
    && {
      width: 60%;
    }
  `
}
