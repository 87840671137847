import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { CreateDataset } from 'ui/components/models/model_create/create_datasets'
import { GenerateDataset } from 'ui/components/models/model_generate/model_generate'
import model_create_styles from 'ui/components/models/model_create/model_create_styles'
import { H2 } from 'ui/styledComponents/constants'
import { ModelConfigure } from 'ui/components/models/model_create/model_configure'
import {
  ModelWizard,
  modelWizardDefault,
  modelWizardStage
} from 'common/redux/sitka/current_session/core'
import { Switch, Route, Redirect } from 'react-router'

interface ModelsChooseProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
}

const Component: React.FC<ModelsChooseProps> = ({
  datasetModule,
  currentSession,
  sitkaState: {
    datasets: { datasets, status }
  }
}) => {
  const [modelWizard, setModelWizard] = useState<ModelWizard>(
    modelWizardDefault
  )

  useEffect(() => {
     datasetModule.handleGetDatasets({})
  }, [])

  const st = strings.modelChooseData
  const {
    CreateModelContainer,
    LoadingSpinner,
    Container,
  } = model_create_styles
  const configure = modelWizard.stage === modelWizardStage.CONFIGURE_MODEL
  const processing = modelWizard.stage === modelWizardStage.PROCESSING

  return (
    <Container>
      <CreateModelContainer>
        <Switch>
          <Route path={'/models/new/dataset'}>
            <CreateDataset {...{ setModelWizard, modelWizard }} />
          </Route>

          <Route path={'/models/new/generate'}>
            <GenerateDataset {...{ setModelWizard, modelWizard }} />
          </Route>          

          <Route path={'/models/new/configure'}>
            {configure ? (
              <ModelConfigure {...{ setModelWizard, modelWizard }} />
            ) : (
              <Redirect to={'/models/new/dataset'} />
            )}
          </Route>

          <Route path={'/models/new/processing'}>
            {processing ? (
              <>
                <H2>{st.processingHeading}</H2>
                <LoadingSpinner />
              </>
            ) : (
              <Redirect to={'/models/new/dataset'} />
            )}
          </Route>

          <Redirect to={'/models/new/dataset'} />
        </Switch>
      </CreateModelContainer>
    </Container>
  )
}

export const ModelCreate = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)
