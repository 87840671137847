import styled from 'styled-components'
import { flex, 
  // cn 
} from 'ui/styledComponents/constants'
// import { InputGroup, IInputGroupProps, Checkbox, 
//   // Icon 
// } from '@blueprintjs/core'

export default {
  GenerateContainer: styled.div`
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  `,
  SettingsContainer: styled.div`
    ${flex.flexCenteredRow}
    justify-content: space-between;
    margin-top: 30px;
  `,
  PredictorContainer: styled.div``,
  AlertBox: styled.div`
    padding: 4px 30px;    
    width: 100%;
    height 100%;
  `,
  AlertText: styled.h4`
    color: #ef0c0c;
  `,

}
