
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { authState } from 'common/redux/sitka/current_session/core'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { loginStyles } from 'ui/login/login_styles'
import LogoImage from 'ui/images/wide_-_color_on_transparent__rgb-02.png'
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from 'react-ga4'



const GOOGLE_ANALYTICS: string = window._env_.REACT_APP_GA || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'

const AUTH0_DOMAIN: string = window._env_.REACT_APP_AUTH0_DOMAIN || ''

const Eula = '<center>By logging in you agree to abide by the terms and conditions set forth in the <a href="https://www.kinandcarta.com/en-us/services/data-and-ai/octain/eula/">open source licensing agreement</a>.</center>'

interface LoginProps {
  readonly currentSession: CurrentSessionModule
  readonly sitkaState: AppState
}

const Component: React.FC<LoginProps> = ({
  currentSession,
  sitkaState: {
    currentSession: { auth, errorCode }
  }
}) => {

  const [agreed, setAgreed] = useState(false);
    
  useEffect(() => {
    // Hook Google Analytics Initialization
    if (GOOGLE_ANALYTICS !== '') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname});
      if (LOGGING) console.log(`GA Page View`, window.location.pathname);
    }
  }, [])

  const {
    Container,
    LoadingSpinner,
    Logo,
    LogoContainer,
    LoginMessageError,
  } = loginStyles

  const { status } = useParams()
  
  const LoginButton = () => {

    const { loginWithPopup, getAccessTokenSilently } = useAuth0();

    const responseAuth0 = (userinfo: any, token: any) => {

        const { name, picture, email } = userinfo;  

        currentSession.handleLogin(
          {
            email: email,
            picture: picture,
            name: name
          },
          token
        )
    }  
    
    const handleLoginButton = () => {

      const opts = {
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: 'userinfo openid profile email'
      };

      (async () => {
        try {
    
          await loginWithPopup(opts);

          const token = await getAccessTokenSilently(opts);

          await fetch(`https://${AUTH0_DOMAIN}/userinfo`, {
                headers: {
                  'Content-type': 'application/json',
                  'Authorization': `Bearer ${token}`, // notice the Bearer before your token
                }
              })
              .then((response) => response.json())
              .then((response) => {
                
                responseAuth0(response, token);

              })
              .catch((err) => {
                console.log(err.message);
               });

        } catch (e) {

          console.error(`UseEffect Error`, e);
          
        }
      })();

    }

    return <div>
        <input
          type="checkbox"
          name="agree"
          id="agree"
          checked={agreed}
          onClick={(e) => {
            setAgreed(e.currentTarget.checked);
          }}
        />
        <label htmlFor="agree"> I agree to the&nbsp;
          <strong>
            <a style={{ color: '#FFF' }} target="_new" 
                href="https://www.kinandcarta.com/en-us/services/data-and-ai/octain/eula/">
                  license agreement</a>
          </strong>.
        </label>
        <br />
        <button disabled={!agreed} onClick={() => handleLoginButton()}>Log In</button>
      </div>;
  };

  // <div dangerouslySetInnerHTML={{__html: Eula}} />
  return (
    <>
    

    <Container>
        <LogoContainer>
          <Logo src={LogoImage} alt={'Octain'} />
        </LogoContainer>

        {auth === authState.UNAUTHENTICATED && status === 'unknown' ? (
        <LoginMessageError>
          <strong>Error:</strong> The user attempting to login is unknown 
          </LoginMessageError>
        ) : (<div/>)}

        {auth === authState.PROCESSING ? (
          <LoadingSpinner />
        ) : (

          <LoginButton></LoginButton>
        )}
      </Container>
    </>
  )

}

export const Login = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    sitkaState: state
  }
})(Component)

interface ErrorMessageProps {
  errorCode: any
}
