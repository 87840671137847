import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { H3, H4, PageHeadingContainer, H2 } from 'ui/styledComponents/constants'
// import { DatasetList } from 'ui/components/models/model_create/dataset_list'
import { useHistory } from 'react-router'
import model_generate_styles from 'ui/components/models/model_generate/model_generate_styles'
// import { DatasetTypes } from 'common/redux/sitka/octain_dataset/core'
import { ButtonPrimary } from 'ui/styledComponents/buttons'
import {
  ModelWizard,
  modelWizardStage,
  modelWizardDefault
} from 'common/redux/sitka/current_session/core'
import {
  InputGroup,
  Menu,
  Position,
  Icon,
  MenuItem,
  Popover,
  RadioGroup,
  Radio,
  Button,
} from '@blueprintjs/core'
import model_styles from 'ui/components/models/model_styles'

// Retrieve our CSS and HTML Styling from model_generate_styles.ts
const {
  AlertBox,
  AlertText,
  SettingsContainer,
  PredictorContainer,
} = model_generate_styles

const st = strings.modelConfigure

// Define the configuration Checking interface
interface ModelConfigCheck {
  readonly modelName: boolean
  // readonly trainingTime: boolean
  readonly datasetType: boolean
}

// Define the defaults for the configuration checks
const ModelConfigCheckDefault = {
  modelName: true,
  // trainingTime: true,
  datasetType: true,
}

interface GenerateDatasetProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
  readonly modelWizard: ModelWizard
  readonly setModelWizard: React.Dispatch<React.SetStateAction<ModelWizard>>
}

const Component: React.FC<GenerateDatasetProps> = ({
  modelWizard,
  setModelWizard,
  datasetModule,
  currentSession,
  sitkaState: {
    datasets: { datasets, status, uploadStatus }
  }
}) => {
  
  const {
    modelName,
    // trainingTime,
    datasetType,
    // method
  } = modelWizard

  const history = useHistory()

  const {
    generateModel,
  } = strings.modelChooseData

  const [formAlert, setFormAlert] = useState<ModelConfigCheck>(
    ModelConfigCheckDefault
  )

  const handleModelNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModelWizard({
      ...modelWizard,
      modelName: event.target.value
    })
  }

  // const handleTrainingTimeChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const number = event.target.value.replace(/\D/g, '')

  //   setModelWizard({
  //     ...modelWizard,
  //     trainingTime: number
  //   })
  // }

  const handleBusinessUseCaseChange = (
    value: string
  ) => {
    setModelWizard({
      ...modelWizard,
      datasetType: value
    })
  }

  // const handleChangeMethod = (event: any) => {
  //   setModelWizard({
  //     ...modelWizard,
  //     method: event.target.value
  //   })
  // }
  
  const generateModelSubmit = () => {
    
    let configCheck: ModelConfigCheck = {
      modelName: modelName.length !== 0,
      datasetType: datasetType !== '',
      // trainingTime: trainingTime !== '' && parseInt(trainingTime) >= 30
    }
    setFormAlert(configCheck)
    
    for (let check of Object.values(configCheck)) {
      if (check === false) {
        return
      }
    }

    currentSession.handleModelGenerateDataset(modelWizard)
    
    setModelWizard({
      ...modelWizard,
      stage: modelWizardStage.PROCESSING
    })

    history.push('/models/new/processing')

  }

  const businessUseCases = [
	  "Customer Churn/Retention",
	  "Probability of Fraud",
	  "Machine Failure",
	  "Demand Forecast",
	  "Revenue Forecast",
	  "Customer Conversion",
  ]

  const {
    GenerateContainer
  } = model_generate_styles

  const { ModelHeadingIcon } = model_styles
  
  // const onChooseDataset = (id: string) => {
  //   setModelWizard({
  //     ...modelWizard,
  //     stage: modelWizardStage.CONFIGURE_MODEL,
  //     datasetId: id
  //   })
  //   history.push('/models/new/configure')
  // }

  useEffect(() => {
    setModelWizard(modelWizardDefault)
  }, [])

  return (
    <>
      <PageHeadingContainer>
        <H2>
          <ModelHeadingIcon />
          {'Create Dataset - Generate Sample'}
        </H2>
        <ButtonPrimary onClick={generateModelSubmit}>
            {'Generate'}
          </ButtonPrimary>
      </PageHeadingContainer>
      <GenerateContainer>
        <H4>{"Sample Parameters"}</H4>
        <SettingsContainer>
          <PredictorContainer>

            <H4>{"Dataset Name"}</H4>
              <Popover
                isOpen={!formAlert.modelName}
                onOpened={() =>
                  setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
                }
              >
                <InputGroup
                  large
                  onChange={handleModelNameChange}
                  placeholder={"My Dataset.."}
                />
                <AlertBox>
                  <AlertText>{"Model name required!"}</AlertText>
                </AlertBox>
              </Popover>

            <H4>{"Business Use Case"}</H4>
            <Popover
              isOpen={!formAlert.datasetType} 
              onOpened={() => 
                setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)}>

              <RadioGroup
                onChange={(event) => handleBusinessUseCaseChange(event.currentTarget.value) } 
                selectedValue={modelWizard.datasetType}
              >
                {businessUseCases.map((object, index) => 
                    <Radio key={index} label={object} value={object} />
                  )}

              </RadioGroup>
              <AlertBox>
                  <AlertText>{"Business use case must be selected!"}</AlertText>
                </AlertBox>
            </Popover>

            {/* <H4>{"Training Time (Seconds)"}</H4>
              <Popover
                isOpen={!formAlert.trainingTime}
                onOpened={() =>
                  setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
                }
              >
                <InputGroup
                  type={'number'}
                  large
                  onChange={handleTrainingTimeChange}
                  placeholder={"30"}
                  value={modelWizard.trainingTime}
                />
                <AlertBox>
                  <AlertText>{"Training time must be at least 30!"}</AlertText>
                </AlertBox>
              </Popover> */}
{/* 
              <H4>{st.trainingMethodHeading}</H4>

              <RadioGroup onChange={handleChangeMethod} selectedValue={method}>
                <Radio large label={st.classificationLabel} value="cls" />
                <Radio large label={st.regressionLabel} value="reg" />
              </RadioGroup> */}

          </PredictorContainer>
        </SettingsContainer>
      </GenerateContainer>
    </>
  )
}

export const GenerateDataset = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)
