import { SitkaModule } from 'olio-sitka'
import { put, select, 
  // call 
} from 'redux-saga/effects'
import {
  OctainPredictionState,
  defaultOctainPredictionResults,
  defaultOctainPredictionFileData,
  OctainPredictionResults
} from 'common/redux/sitka/octain_prediction/core'
import { AppModules, AppState } from 'common/redux/sitka/sitka_octain'
import {
  defaultRepository,
  parseArrayToBaseRepo,
  removeFromBaseRepo
} from 'common/redux/sitka/basemap'
import {
  ResponseModel,
  getPredictions,
  getPrediction,
  get_file,
  patchPrediction
} from 'common/client'
import { FetchStatus } from 'enums'
import {
  parseCSVinPrediction,
  getDataFromResponse,
  getResponseStatus,
  parseCSVinPredictions
} from 'common/redux/sitka/octain_prediction/util'
import { parseCSV } from 'common/redux/sitka/octain_dataset/util'
import {
  patchPredictionsParams,
  getPredictionsParams
} from 'common/request_shape'

export class PredictionModule extends SitkaModule<
  OctainPredictionState,
  AppModules
> {
  public moduleName: string = 'predictions'

  public defaultState: OctainPredictionState = {
    predictions: defaultRepository(),
    status: FetchStatus.none,
    predictionResults: defaultOctainPredictionResults,
    predictionResultsStatus: FetchStatus.none
  }

  public *handlePatchPrediction(id: string, params: patchPredictionsParams) {
    const response: ResponseModel = yield patchPrediction(id, params)
    if (response.status === 200) {
      const predictionState: OctainPredictionState = yield select(
        PredictionModule.selectPredictionState
      )
      if (params.archived !== undefined) {
        yield put(
          this.setState({
            ...predictionState,
            predictions: removeFromBaseRepo(id, predictionState.predictions),
            status: FetchStatus.done
          })
        )
      }
      if (
        params.archived !== undefined &&
        predictionState.predictionResults.id
      ) {
        yield put(
          this.setState({
            ...predictionState,
            predictionResults: {
              ...predictionState.predictionResults,
              archived: params.archived
            },
            status: FetchStatus.done
          })
        )
      }
    }
  }

  public *handleGetPredictions(params: getPredictionsParams) {
    const predictionState: OctainPredictionState = yield select(
      PredictionModule.selectPredictionState
    )

    yield put(
      this.setState({ ...this.defaultState, status: FetchStatus.loading })
    )

    const rawPredictions: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getPredictions(params)
    )
    if (rawPredictions.status !== 200) {
      yield put(
        this.setState({ ...this.defaultState, status: FetchStatus.error })
      )
      return
    }

    yield put(
      this.setState({
        ...predictionState,
        predictions: parseArrayToBaseRepo(
          parseCSVinPredictions(rawPredictions.data)
        ),
        status: FetchStatus.done
      })
    )
  }

  public *handleGetPredictionResults(
    predictionId: string,
    forceFetch: boolean = false
  ): {} {
    const loadingPredictionState: OctainPredictionState = yield select(
      PredictionModule.selectPredictionState
    )

    // Check for prediction in state, if found copy old state to results
    if (loadingPredictionState.predictions.items[predictionId] && !forceFetch) {
      const copyPrediction: OctainPredictionResults = {
        ...defaultOctainPredictionResults,
        ...loadingPredictionState.predictions.items[predictionId],
        fileData: defaultOctainPredictionFileData(FetchStatus.loading)
      }
      yield put(
        this.setState({
          ...loadingPredictionState,
          predictionResultsStatus: FetchStatus.done,
          predictionResults: copyPrediction
        })
      )
    } else {
      yield put(
        this.setState({
          ...loadingPredictionState,
          predictionResultsStatus: FetchStatus.loading,
          predictionResults: {
            ...defaultOctainPredictionResults,
            fileData: defaultOctainPredictionFileData(FetchStatus.loading)
          }
        })
      )
    }

    const results: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getPrediction(predictionId)
    )
    if (results.status !== 200) {
      const predictionState: OctainPredictionState = yield select(
        PredictionModule.selectPredictionState
      )
      yield put(
        this.setState({
          ...predictionState,
          predictionResultsStatus: FetchStatus.error,
          predictionResults: {
            ...predictionState.predictionResults,
            fileData: defaultOctainPredictionFileData(FetchStatus.error)
          }
        })
      )
      return
    }
    const prediction = parseCSVinPrediction(results.data[0])

    const predictionState: OctainPredictionState = yield select(
      PredictionModule.selectPredictionState
    )

    yield put(
      this.setState({
        ...predictionState,
        predictionResultsStatus: FetchStatus.done,
        predictionResults: {
          ...predictionState.predictionResults,
          ...prediction
        }
      })
    )
    if (
      prediction.state === 'DONE' ||
      prediction.state === 'PROCESSING' ||
      prediction.state === 'ERROR'
    ) {
      const log: ResponseModel = yield get_file(prediction.files['log.txt'])
      yield this.updateFileData('log', 'logStatus', log, false)
    } else {
      yield this.updateFileData(
        'log',
        'logStatus',
        { status: 200, statusText: '', data: 'no log entries' },
        false
      )
    }
  }

  public *updateFileData(
    dataName: string,
    dataStatus: string,
    response: ResponseModel,
    CSV: boolean = true
  ) {
    const predictionState = yield select(PredictionModule.selectPredictionState)
    yield put(
      this.setState({
        ...predictionState,
        predictionResults: {
          ...predictionState.predictionResults,
          fileData: {
            ...predictionState.predictionResults.fileData,
            [dataName]: CSV
              ? parseCSV(getDataFromResponse(response))
              : getDataFromResponse(response),
            [dataStatus]: getResponseStatus(response)
          }
        }
      })
    )
  }

  public static selectPredictionState(state: AppState): OctainPredictionState {
    return state.predictions
  }
}
