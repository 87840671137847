import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { useParams } from 'react-router'
import {
  Container,
  H2,
  H3,
  PageHeadingContainer,
  // H4,
  RefreshButton
} from 'ui/styledComponents/constants'
import { strings } from 'ui/components/strings'
import { FetchStatus } from 'enums'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { PredictionLog } from 'ui/components/predictions/prediction_results/prediction_results_log'
import { PredictionResultsPreview } from 'ui/components/predictions/prediction_results/prediction_results_preview'
import { PredictionResultsParameters } from 'ui/components/predictions/prediction_results/prediction_results_parameters'
import results_styles from 'ui/styledComponents/results_styles'
import predictions_styles from 'ui/components/predictions/predictions_styles'
import { SmallToggleButton, SmallButton } from 'ui/styledComponents/buttons'
import { Popover, Position } from '@blueprintjs/core' // Icon
import { PredictionActionMenu } from 'ui/components/predictions/prediction_action_menu'
// import prediction_list_styles from 'ui/components/predictions/prediction_list_styles'

interface Props {
  readonly sitkaState: AppState
  readonly predictionModule: PredictionModule
}

const Component: React.FC<Props> = ({
  sitkaState: {
    predictions: { predictionResults, predictionResultsStatus }
  },
  predictionModule
}) => {
  const { predictionId } = useParams()

  const { pageHeading } = strings.predictionResults
  const { name, state, createdAt, id, archived } = predictionResults
  const { log, logStatus } = predictionResults.fileData

  const [pageSelect, setPageSelect] = useState<string>('Parameters')

  useEffect(() => {
    if (predictionId) {
      predictionModule.handleGetPredictionResults(predictionId)
    }
  }, [])

  const {
    PageBody,
    PageButtonInactive,
    PageButtonActive,
    PageContainer,
    Tab,
    PageCategoryHeading,
    PageBodyContainer,
    LoadingSpinner,
    ActionMenuIcon
  } = results_styles
  const { PredictionsHeadingIcon } = predictions_styles
  const { PageButtonContainer } = predictions_styles
  const PageComponent =
    pageSelect === 'Logs' ? (
      <PredictionLog log={log} status={logStatus} />
    ) : pageSelect === 'Preview' ? (
      <PredictionResultsPreview />
    ) : pageSelect === 'Parameters' ? (
      <PredictionResultsParameters />
    ) : (
      ''
    )

  const PredictionHeading = name ? (
    <H2>
      <PredictionsHeadingIcon />
      {archived ? 'Archived - ' + name : name}
    </H2>
  ) : (
    <div />
  )

  const CreatedAtHeading = name ? (
    <H3>Created On - {createdAt.toString()}</H3>
  ) : (
    <div />
  )

  interface PageButtonProps {
    readonly id: string
  }

  const PageButton: React.FC<PageButtonProps> = ({ id }) => {
    return pageSelect === id ? (
      <PageButtonActive onClick={() => setPageSelect(id)}>
        {id}
      </PageButtonActive>
    ) : (
      <PageButtonInactive onClick={() => setPageSelect(id)}>
        {id}
      </PageButtonInactive>
    )
  }

  const done = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Preview'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBody>{PageComponent}</PageBody>
    </PageBodyContainer>
  )

  const loading = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBody>
        <LoadingSpinner />
      </PageBody>
    </PageBodyContainer>
  )

  const error = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBody>{PageComponent}</PageBody>
    </PageBodyContainer>
  )
  const none = loading

  const status: FetchStatus =
    (state !== '' && state !== 'DONE') ||
    predictionResultsStatus === FetchStatus.error
      ? FetchStatus.error
      : predictionResultsStatus

  const getResults = () => {
    if (predictionId) {
      predictionModule.handleGetPredictionResults(predictionId, true)
    }
  }

  return (
    <Container>
      <PageHeadingContainer>
        {PredictionHeading}
        {CreatedAtHeading}
        <PageButtonContainer>
          <SmallToggleButton
            onClick={getResults}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
          <Popover position={Position.BOTTOM_LEFT}>
            <SmallButton>
              <ActionMenuIcon />
            </SmallButton>
            <PredictionActionMenu id={id} prediction={predictionResults} />
          </Popover>
        </PageButtonContainer>
      </PageHeadingContainer>
      <PageContainer>
        <PageCategoryHeading>
          <H2>{pageHeading(pageSelect)}</H2>
        </PageCategoryHeading>
        <LoadingWrapper {...{ done, loading, error, none, status }} />
      </PageContainer>
    </Container>
  )
}

export const PredictionResults = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
