import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { PredictionList } from 'ui/components/predictions/prediction_list'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { ButtonPrimary, SmallToggleButton } from 'ui/styledComponents/buttons'
import {
  SLink,
  Container,
  H2,
  PageHeadingContainer,
  H1,
  RefreshButton
} from 'ui/styledComponents/constants'
import predictions_styles from 'ui/components/predictions/predictions_styles'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import { FetchStatus } from 'enums'
import ReactGA from 'react-ga4'

const GOOGLE_ANALYTICS: string = window._env_.REACT_APP_GA || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'
const REFRESH_EVERY = 25 * 1000

interface PredictionsProps {
  readonly modelModule: ModelModule
  readonly predictionModule: PredictionModule
  readonly sitkaState: AppState
}

const Component: React.FC<PredictionsProps> = ({
  predictionModule,
  sitkaState: {
    predictions: { predictions, status }
  }
}) => {
  const history = useHistory()
  const [archived, setArchived] = useState<boolean>(false)
  const [reloadPredictions, setReloadPredictions] = useState<number>(-1)

  const getPredictions = () => {
    predictionModule.handleGetPredictions({ archived: archived })
  }
  
  useEffect(
    () => () => {
      // Clear the setInterval anytime leaving page
      if (reloadPredictions !== -1) {
        clearInterval(reloadPredictions)
        setReloadPredictions(-1)
      }
    },
    [reloadPredictions]
  );

  useEffect(() => {
    predictionModule.handleGetPredictions({ archived: false })

    // Hook Google Analytics Initialization
    if (GOOGLE_ANALYTICS !== '') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname});
      if (LOGGING) console.log(`GA Page View`, window.location.pathname);
    }

    // reload the page data every interval until we know all data is DONE
    setReloadPredictions(setInterval(getPredictions, REFRESH_EVERY))
  }, [history])

  const {
    EmptyPredictionsList,
    PredictionsHeadingIcon,
    PageButtonContainer,
    LoadingSpinner,
    PredictionArchiveIcon
  } = predictions_styles

  const loading = (
    <>
      <EmptyPredictionsList>
        <LoadingSpinner />
      </EmptyPredictionsList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const none = loading

  const error = (
    <>
      <EmptyPredictionsList>
        <H1>Network Error</H1>
      </EmptyPredictionsList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const done =
    status === FetchStatus.done && predictions.sort.length === 0 ? (
      <>
        <EmptyPredictionsList>
          {archived ? (
            <h2>{'No Predictions in archive'}</h2>
          ) : (
            <h2>{'No Predictions'}</h2>
          )}
        </EmptyPredictionsList>
        <PageButtonContainer>
          <PageButtons />
        </PageButtonContainer>
      </>
    ) : (
      <PredictionList predictions={predictions} reloadIntervalId={reloadPredictions} status={status} />
    )

  const toggleArchive = () => {
    predictionModule.handleGetPredictions({ archived: !archived })
    setArchived(!archived)
  }


  return (
    <Container>
      <PageHeadingContainer>
        <H2>
          <PredictionsHeadingIcon />
          {archived ? 'Predictions - Archive' : 'Predictions'}
        </H2>
        <PageButtonContainer>
          <SLink to={'/predictions/new'}>
            <ButtonPrimary>New Prediction</ButtonPrimary>
          </SLink>
          <SmallToggleButton
            onClick={getPredictions}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
          <SmallToggleButton onClick={toggleArchive} active={archived}>
            <PredictionArchiveIcon />
          </SmallToggleButton>
        </PageButtonContainer>
      </PageHeadingContainer>
      <LoadingWrapper {...{ done, loading, error, none, status }} />
    </Container>
  )
}

export const Predictions = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    modelModule: modules.models,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
