import styled from 'styled-components'
import { flex, 
  // cn 
} from 'ui/styledComponents/constants'
import { InputGroup, IInputGroupProps, Checkbox, 
  // Icon 
} from '@blueprintjs/core'

export default {
  ConfigureContainer: styled.div`
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  `,
  InputGroup: styled(InputGroup).attrs(
    (): IInputGroupProps => ({ leftIcon: 'pie-chart' })
  )``,
  SettingsContainer: styled.div`
    ${flex.flexCenteredRow}
    justify-content: space-between;
    margin-top: 30px;
  `,
  PreviewDataContainer: styled.div`
    width: 100%;
    height: 50%;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    ${flex.flexCenteredColumn}
    justify-content: center;
  `,
  PredictorContainer: styled.div``,
  PredictorList: styled.div`
    height: 420px;
    overflow-y: auto;
  `,
  PredictorInput: styled(InputGroup)`
    margin-bottom: 10px;
  `,
  PredictorListUnchecked: styled(Checkbox)`
    &&&&& {
      height: 30px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
    }
  `,
  PredictorListPredictors: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #137cbd;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
      color: white;
    }
  `,
  PredictorListIdVariable: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #fd7de7;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
      color: white;
    }
  `,
  PredictorListResponse: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #7cfd80;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
    }
  `,
  AlertBox: styled.div`
    padding: 4px 30px;    
    width: 100%;
    height 100%;
  `,
  AlertText: styled.h4`
    color: #ef0c0c;
  `,
}
