import React, { useEffect } from 'react'
import { H4 } from 'ui/styledComponents/constants'
import { strings } from 'ui/components/strings'
import home_styles from 'ui/components/home/home_styles'
import ReactGA from 'react-ga4'

const GOOGLE_ANALYTICS: string = window._env_.REACT_APP_GA || ''
const LOGGING = window._env_.REACT_APP_LOGGING === 'true'

const Home: React.FC = () => {

  useEffect(() => {   
    // Hook Google Analytics Initialization
    if (GOOGLE_ANALYTICS !== '') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname});
      if (LOGGING) console.log(`GA Page View`, window.location.pathname);
    }
  }, [])

  const {
    home: { modelHeading, modelBody, predictHeading, predictBody }
  } = strings

  const { Container, Card, ModelIcon, PredictIcon, CardLink } = home_styles

  return (
    <Container>
      <CardLink to={'/models'}>
        <Card>
          <H4>
            <ModelIcon /> {modelHeading}
          </H4>
          <p>{modelBody}</p>
        </Card>
      </CardLink>
      <CardLink to={'/predictions'}>
        <Card>
          <H4>
            <PredictIcon />
            {predictHeading}
          </H4>
          <p>{predictBody}</p>
        </Card>
      </CardLink>
    </Container>
  )
}

export { Home }
