import React from 'react'
// import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import octain_navbar_styles from 'ui/components/octain_navbar/octain_navbar_styles'
import { authState } from 'common/redux/sitka/current_session/core'
import { SLink } from 'ui/styledComponents/constants'
import Logo from 'ui/images/cut_-_color_on_transparent__rgb-02.png'

interface NavbarProps {
  readonly currentSession: CurrentSessionModule
  readonly sitkaState: AppState
}

const Component: React.FC<NavbarProps> = ({
  sitkaState: {
    currentSession: {
      session: { picture, name, email, permissions },
      auth
    }
  },
  currentSession: { handleLogout }
}) => {
  const authenticated = auth === authState.AUTHENTICATED

  const {
    //heading,
    modelButton,
    predictionButton,
    //resultsButton,
    logoutButton
  } = strings.navBar
  const {
    Email,
    Group,
    UserGroup,
    Navbar,
    UserBox,
    UserCard,
    MenuItems: { 
      Models, 
      Predictions, 
      //Results, 
      Logout, 
      Admin },
    //Heading,
    Divider,
    HeadingIcon
  } = octain_navbar_styles

  const admin = permissions === 'ADMIN'

  return (
    <Navbar>
      <Group>
        <SLink to={'/home'}>
          <HeadingIcon>
            <img src={Logo} alt={'Octain'} />
          </HeadingIcon>
        </SLink>
        <Divider />
        {authenticated && (
          <>
            <SLink to={'/models'}>
              <Models text={modelButton} />
            </SLink>
            <SLink to={'/predictions'}>
              <Predictions text={predictionButton} />
            </SLink>
            {admin && (
              <SLink to={'/admin'}>
                <Admin text={'Admin'} />
              </SLink>
            )}
          </>
        )}
      </Group>
      {authenticated && (
        <UserGroup>
          <UserCard>
            <img src={picture} alt="Google Avatar" />
            <UserBox>
              <div>{name}</div>
              <Email>{email}</Email>
            </UserBox>
          </UserCard>
          <Logout
            onClick={() => handleLogout('')}
            text={logoutButton}
          />
        </UserGroup>
      )}
    </Navbar>
  )
}

export const OctainNavbar = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    sitkaState: state
  }
})(Component)
