import React from 'react'
import { strings } from 'ui/components/strings'
import { Menu, 
  // Popover, Position 
} from '@blueprintjs/core'
import prediction_list_styles from 'ui/components/predictions/prediction_list_styles'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { connect } from 'react-redux'
import { OctainPrediction } from 'common/redux/sitka/octain_prediction/core'

interface Props {
  readonly predictionModule: PredictionModule
  readonly prediction: OctainPrediction
  readonly id: string
}

const Component: React.FC<Props> = ({ id, prediction, predictionModule }) => {
  const {
    DownloadCSV,
    DownloadLog,
    Archive,
    UnArchive
  } = prediction_list_styles.ActionMenu
  const { archive, 
    // duplicate, exportText 
  } = strings.modelList.actionMenu
  // const { MoreButton } = prediction_list_styles

  const { archived, state } = prediction
  const {
    'log.txt': downloadLog,
    'prediction.csv': downloadPrediction
  } = prediction.files
  const done = state === 'DONE'

  const archiveItem = () => {
    predictionModule.handlePatchPrediction(id, { archived: true })
  }
  const unArchiveItem = () => {
    predictionModule.handlePatchPrediction(id, { archived: false })
  }

  return (
    <Menu>
      {archived ? (
        <UnArchive text={'Restore'} onClick={unArchiveItem} />
      ) : (
        <Archive text={archive} onClick={archiveItem} />
      )}
      {done && (
        <>
          <DownloadCSV
            text={'Download CSV'}
            href={downloadPrediction}
            download={'prediction.csv'}
          />
          <DownloadLog text={'Download Log'} href={downloadLog} />
        </>
      )}
    </Menu>
  )
}

export const PredictionActionMenu = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
