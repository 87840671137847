import styled from 'styled-components'
import { Button, 
  // MenuItem, 
  Icon, 
  // Radio, 
  RadioGroup } from '@blueprintjs/core'
// import { color } from 'ui/styledComponents/constants'

export default {
  SelectedTableRow: styled.tr`
    background-color: aliceblue;
  `,

  Border: styled.div`
    width: 100%;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 760px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  `,
  ModelTable: styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
    font-size: 18px;
    thead {
      background-color: #f9f9f9;
      color: #858599;
      tr {
        height: 40px;
      }
    }
    td {
      border-bottom: 1px solid #ddd;
      padding-left: 10px;
      padding-right: 10px;
    }
    tr {
      height: 80px;
      th:first-child {
        padding-left: 35px;
      }
      td:first-child {
        padding-left: 35px;
      }
    }

    th {
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }

    tbody {
      font-weight: 600;
      tr:nth-child(10) {
        td {
          border-bottom: none;
        }
      }
    }
  `,
  ColumnName: styled.th`
    width: 10%;
    min-width: 150px;
  `,
  ColumnRuntime: styled.th`
    width: 12%;
    min-width: 140px;
  `,
  ColumnVariance: styled.th`
    width: 30%;
    min-width: 200px;
  `,
  ColumnActions: styled.th`
    width: 10%;
    min-width: 200px;
    text-align: end;
    padding-right: 40px;
  `,
  RadioGroup: styled(RadioGroup)`
    & label {
      margin-bottom: 0px;
    }
  `,
  NewPredictionButton: styled(Button).attrs(() => ({
    minimal: true,
    large: true
  }))``,
  MoreButton: styled(Button).attrs(() => ({
    minimal: true,
    large: true,
    icon: 'more'
  }))``,
  ActionIcons: {
    Edit: styled(Icon).attrs(() => ({
      icon: 'edit',
      iconSize: 20
    }))``,
    Cancel: styled(Icon).attrs(() => ({
      icon: 'cross',
      iconSize: 20
    }))``,
    Confirm: styled(Icon).attrs(() => ({
      icon: 'confirm',
      iconSize: 20
    }))``
  }
}
