import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { Popover, Position, Menu } from '@blueprintjs/core'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import prediction_list_styles from 'ui/components/predictions/prediction_list_styles'
import { SLink } from 'ui/styledComponents/constants'
import { OctainPredictions } from 'common/redux/sitka/octain_prediction/core'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { PredictionActionMenu } from 'ui/components/predictions/prediction_action_menu'
import { FetchStatus } from 'enums'

interface predictionListProps {
  readonly currentSession: CurrentSessionModule
  readonly predictionModule: PredictionModule
  readonly predictions: OctainPredictions
  readonly sitkaState: AppState
  readonly reloadIntervalId: number
  readonly status: number
}

const Component: React.FC<predictionListProps> = ({
  predictions,
  predictionModule, 
  reloadIntervalId,
  status
}) => {
  const [page, setPage] = useState(0)
  const resultsPerPage = 10
  const displaySort = predictions
    ? predictions.sort.slice(page * resultsPerPage, (page + 1) * resultsPerPage)
    : []
  const totalPages = Math.ceil(predictions.sort.length / resultsPerPage)
  const disableReload = ['DONE', 'ERROR']

  useEffect(() => {
    if (status === FetchStatus.done && 
        page === 0 && 
        (displaySort.length === 0 || disableReload.includes(predictions.items[displaySort[0]].state))) {
        clearInterval(reloadIntervalId)
    }
  }, [])

  const {
    columnName,
    columnRuntime,
    columnVariance,
    columnActions,
    newPredictionButton
  } = strings.modelList
  const {
    Border,
    ModelTable,
    ColumnName,
    ColumnRuntime,
    ColumnVariance,
    ColumnActions,
    NewPredictionButton,
    MoreButton,
    HTMLFrame
  } = prediction_list_styles

  const ModelList = displaySort.map(id => {
    const { state, name, updatedAt } = predictions.items[id]
    // TODO: add loading states
    // TODO: add string constants
    const trainingStatus = strings.ProgressState(state)

    return (
      <tr key={id}>
        <td>
          <SLink to={`/predictions/${id}`}>{name}</SLink>
        </td>
        <td>{trainingStatus}</td>
        <td>{updatedAt}</td>
        <td>
          <Popover position={Position.BOTTOM_LEFT}>
            <MoreButton />
            <PredictionActionMenu id={id} prediction={predictions.items[id]} />
          </Popover>
        </td>
      </tr>
    )
  })

  return (
    <>
      <Border>
        <ModelTable>
          <thead>
            <tr>
              {/* TODO: Update Columns and fields */}
              <th>Name</th>
              <th>State</th>
              <th>Last Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{ModelList}</tbody>
        </ModelTable>
      </Border>
      <PageButtons {...{ page, setPage, totalPages }} />
    </>
  )
}

export const PredictionList = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
