import React from 'react'
import { OctainDataset } from 'common/redux/sitka/octain_dataset/core'
// import { strings } from 'ui/components/strings'
import preview_list_styles from 'ui/components/models/model_create/preview_list_styles'
import { OctainModel } from 'common/redux/sitka/octain_model/core'

interface previewListProps {
  dataset: OctainDataset
  model: OctainModel
}

const PreviewList: React.FC<previewListProps> = ({ dataset, model }) => {
  const {
    ModelTable,
    DatalistContainer,
    SelectedCell,
    IDCell,
    ResponseCell,
    IndexCell
  } = preview_list_styles

  const idColumn = model.idVariable
  const responseColumn = model.responseVariable
  const predictorColumns = model.predictionVariables.split(', ')

  const columns = dataset.preview[0]
  const rows = dataset.preview.slice(1)
  interface TableRowProps {
    row: ReadonlyArray<string>
  }

  const TableRow: React.FC<TableRowProps> = ({ row }) => {
    return (
      <tr>
        {row.map((item, i) => {
          const text = clipCell(item)
          return i === 0 ? (
            <IndexCell key={'tableRow' + i}>{text}</IndexCell>
          ) : idColumn === columns[i] ? (
            <IDCell key={'tableRow' + i}>{text}</IDCell>
          ) : responseColumn === columns[i] ? (
            <ResponseCell key={'tableRow' + i}>{text}</ResponseCell>
          ) : predictorColumns.includes(columns[i]) ? (
            <SelectedCell key={'tableRow' + i}>{text}</SelectedCell>
          ) : (
            <td key={'tableRow' + i}>{text}</td>
          )
        })}
      </tr>
    )
  }

  return (
    <DatalistContainer>
      <ModelTable>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={'previewList' + column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <TableRow key={row[0]} row={row} />
          ))}
        </tbody>
      </ModelTable>
    </DatalistContainer>
  )
}

const clipCell = (item: string) => {
  if (item.length > 40) {
    return item.slice(0, 37) + ' ...'
  }
  return item
}

export { PreviewList }
