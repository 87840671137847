import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { OctainModels } from 'common/redux/sitka/octain_model/core'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { Popover, Position, Menu } from '@blueprintjs/core'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import model_list_styles from 'ui/components/models/model_list_styles'
import { useHistory } from 'react-router'
import { SLink } from 'ui/styledComponents/constants'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { ModelActionMenu } from 'ui/components/models/model_action_menu'
import { FetchStatus } from 'enums'

interface modelListProps {
  readonly currentSession: CurrentSessionModule
  readonly modelModule: ModelModule
  readonly models: OctainModels
  readonly sitkaState: AppState
  readonly reloadIntervalId: number
  readonly status: number
}

const Component: React.FC<modelListProps> = ({ models, modelModule, reloadIntervalId, status }) => {
  const [page, setPage] = useState(0)

  const resultsPerPage = 10
  const displaySort = models
    ? models.sort.slice(page * resultsPerPage, (page + 1) * resultsPerPage)
    : []
  const totalPages = Math.ceil(models.sort.length / resultsPerPage)
  const history = useHistory()
  const disableReload = ['DONE', 'ERROR']

  useEffect(() => {
    if (status === FetchStatus.done && 
      page === 0 && 
      (displaySort.length === 0 || disableReload.includes(models.items[displaySort[0]].state)) &&
      reloadIntervalId !== -1) {
        clearInterval(reloadIntervalId)
    }
  }, [])

  const {
    columnName,
    columnRuntime,
    columnVariance,
    columnActions,
    newPredictionButton
  } = strings.modelList
  const {
    Border,
    ModelTable,
    ColumnName,
    ColumnRuntime,
    ColumnVariance,
    ColumnActions,
    NewPredictionButton,
    MoreButton,
    HTMLFrame
  } = model_list_styles

  const ModelList = displaySort.map(id => {
    const { name, trainingTime, accuracy, state, updatedAt } = models.items[id]
    // TODO: add loading states
    // TODO: add string constants
    const trainingStatus = strings.ProgressState(state)
    const accuracyPercentage = accuracy ? accuracy + '%' : ''

    return (
      <tr key={id}>
        <td>
          <SLink to={`/models/${id}`}>{name}</SLink>
        </td>
        <td>{trainingTime}</td>
        <td>{trainingStatus}</td>
        <td>{updatedAt}</td>
        <td>{accuracyPercentage}</td>
        <td>
          {/* TODO: Add link to new prediction wizard */}
          {/* <NewPredictionButton text={newPredictionButton} /> */}
          <Popover position={Position.BOTTOM_LEFT}>
            <MoreButton />
            <ModelActionMenu id={id} model={models.items[id]} />
          </Popover>
        </td>
      </tr>
    )
  })

  return (
    <>
      <Border>
        <ModelTable>
          <thead>
            <tr>
              {/* TODO: Update Columns and fields */}
              <th>Name</th>
              <th>Runtime</th>
              <th>State</th>
              <th>Last Updated</th>
              <th>Accuracy</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{ModelList}</tbody>
        </ModelTable>
      </Border>
      <PageButtons {...{ page, setPage, totalPages }} />
    </>
  )
}

export const ModelList = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    modelModule: modules.models,
    sitkaState: state
  }
})(Component)